import React, { forwardRef } from 'react';
import { useRecoilState } from 'recoil';
import { useCookies } from "react-cookie";
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from "reactstrap";

import logo from "../../assets/images/logo.png";
import { collapsedState } from '../../utils/recoil-atoms';
import { getProfilePic, getUserEmail, isLoggedIn, logout, getUserName, getcookirgg } from "../../functions/Auth";
import { bannerButton } from "../../functions/Survey";
import { getCurrentLang, supportedLanguages } from '../../i18n';
import { delay } from '../../utils/utils';
import { Banner } from './Banner';

const Navbar = (props, navbarref) => {
    const location = useLocation();
    const [cookies, setCookie, removeCookie] = useCookies(['access_token']);
    const [collapsed, setCollapsed] = useRecoilState(collapsedState);
    const [profilePic, setProfilePic] = React.useState("");
    const [userName, setuserName] = React.useState("");
    const [dropdownCollegeMode, setDropdownCollegeMode] = React.useState(false);
    const [btnData, setBtnData] = React.useState({})
    const [selectLanguage, setSelectLanguage] = React.useState('');
    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    };

    React.useEffect(() => {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);

        getUserName().then((name) => {
            setuserName(name)
        });
        bannerButton().then((res) => {
            setBtnData(res)
        })

        if (!localStorage.getItem("profilePic")) {
            getProfilePic().then((profilePic) => {
                if (profilePic !== "") {
                    localStorage.setItem("profilePic", profilePic);
                    setProfilePic(profilePic);
                }
            });
        } else {
            setProfilePic(localStorage.getItem("profilePic"));

        }
        setSelectLanguage(getCurrentLang())
    }, []);

    const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    return (
        <React.Fragment>
            <div id="navbar" ref={navbarref} className="navbar-area" style={{ minHeight: collapsed ? 'auto' : '100vh', boxSizing: 'border-box' }}>
                <div className="tarn-nav" style={{ borderWidth: collapsed ? '2px' : '0' }}>
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <Link to="/" className="navbar-brand">
                                <img src={logo} alt="logo" width={192} style={{ maxWidth: "100%" }} />
                            </Link>
                            <button
                                onClick={toggleNavbar}
                                className={classTwo}
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="icon-bar top-bar" />
                                <span className="icon-bar middle-bar" />
                                <span className="icon-bar bottom-bar" />
                            </button>

                            <div className="others-option navothers-option d-flex align-items-center others-option-flex-nowrap">
                                {location.pathname !== "/survey-home" ? (
                                    <div className="option-item">
                                        <NavLink to="/survey-home"

                                            activeClassName="active"
                                            className="default-btn">
                                            <i style={{ display: "block" }} className="bx bxs-edit-alt" /><p>{t('開始測試')}</p>
                                        </NavLink>
                                    </div>
                                ) : ""}
                                <div className="option-item">
                                    <NavLink to="/profile/payment-history"
                                        activeClassName="active"
                                        className="default-btn">
                                        <i style={{ display: "block" }} className="bx bxs-edit-alt premiumicon" /><p>{t('購買Premium計劃')}</p>
                                    </NavLink>
                                </div>
                                {
                                    btnData.result == 'success' ?
                                        <div className="option-item">
                                            <Link to={btnData.redirect_url}
                                                activeClassName="active"
                                                className="default-btn">
                                                <p>{btnData.description}</p>
                                            </Link>
                                        </div> : <div></div>
                                }


                            </div>





                            <div className={classOne} id="navbarSupportedContent">
                                {!isLoggedIn(cookies) ? (
                                    <div className="others-option navnologin d-flex align-items-center displaymobile ">
                                        <div className="option-item login-option-item">
                                            <p className='bxs-face-nologin'>
                                                <i className='bx bxs-face' style={{ fontSize: "50px", width: "50px", height: "50px" }} />
                                            </p>
                                            <NavLink onClick={e => {
                                                setCollapsed(!collapsed);
                                            }} to="/login"
                                                activeClassName="active"
                                                className="default-btn">
                                                <i className="flaticon-right" />{t('登入')}
                                            </NavLink>

                                        </div>
                                    </div>
                                ) : ""}
                                <ul className="navbar-nav">
                                    {/* mobile */}

                                    {isLoggedIn(cookies) ? (
                                        <li className="nav-item displaymobile">
                                            <a href="#"
                                                onClick={e => e.preventDefault()}
                                                className="nav-link loginface align-items-center p-2">
                                                {profilePic === "" ?
                                                    <i className='bx bxs-face' style={{ fontSize: "50px", width: "50px", height: "50px" }} /> :
                                                    <img src={profilePic} width={50} height={50} alt={"profile-pic"} />
                                                }
                                            </a>
                                            <p className='nav-item-top nav-item-top-name'>{userName}</p>
                                            <p className='nav-item-top'>
                                                {getUserEmail(cookies)}
                                            </p>
                                            <ul className='nav-item-btn'>
                                                <li>
                                                    <Link className="nav-link" to="/profile">
                                                        {t('用戶個人檔案')}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <a href="#"
                                                        onClick={e => {
                                                            logout(removeCookie);
                                                            setCollapsed(!collapsed);
                                                            let linkPath = '';
                                                            getCurrentLang() === "zh-hant" ? '/' : (getCurrentLang() === "cn" ? '/cn' : '/en')
                                                            window.location = linkPath;
                                                            e.preventDefault();
                                                        }}
                                                        className="nav-link">
                                                        {t('登出')}
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    ) : ""}


                                    <li className="nav-item">
                                        <NavLink exact to="/"
                                            activeClassName="active"
                                            className="nav-link">
                                            {t('主頁')}
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <UncontrolledDropdown nav inNavbar>
                                            <DropdownToggle nav caret>
                                                {t('選科懶人包')}
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                <DropdownItem>
                                                    <NavLink to="/techher2"
                                                        activeClassName="active"
                                                        className="nav-link">
                                                        {t('虚擬校園遊覽')}
                                                    </NavLink>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <NavLink to="/discipline"
                                                        activeClassName="active"
                                                        className="nav-link">
                                                        {t('大學揀邊科')}
                                                    </NavLink>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <NavLink to="/dormitory"
                                                        activeClassName="active"
                                                        className="nav-link">
                                                        {t('大學宿舍逐一看')}
                                                    </NavLink>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <NavLink to="/restaurant"
                                                        activeClassName="active"
                                                        className="nav-link">
                                                        {t('大學餐廳逐一看')}
                                                    </NavLink>
                                                </DropdownItem>

                                                <DropdownItem>
                                                    <NavLink to="/activities"
                                                        activeClassName="active"
                                                        className="nav-link">
                                                        {t('大學課外活動')}
                                                    </NavLink>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <NavLink to="/balance"
                                                        activeClassName="balance"
                                                        className="nav-link">
                                                        {t('大學學習-生活-平衡')}
                                                    </NavLink>
                                                </DropdownItem>

                                            </DropdownMenu>

                                        </UncontrolledDropdown>


                                    </li>

                                    <li className="nav-item">
                                        <NavLink to="/career-brief"
                                            activeClassName="active"
                                            className="nav-link">
                                            {t('職業分析')}
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/salary-compare"
                                            activeClassName="active"
                                            className="nav-link">
                                            {t('薪金對比系統')}
                                        </NavLink>
                                    </li>

                                    <li className="nav-item">
                                        <UncontrolledDropdown nav inNavbar>
                                            <DropdownToggle nav caret>
                                                {t('眾裔·職語')}
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                <DropdownItem>
                                                    <NavLink to="/ethnic-minorities"
                                                        activeClassName="active"
                                                        className="nav-link">
                                                        {t('少數族裔職場廣東話教材')}
                                                    </NavLink>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <NavLink to="/ethnic-internship"
                                                        activeClassName="active"
                                                        className="nav-link">
                                                        {t('少數族裔實習評分證書招聘平台')}
                                                    </NavLink>
                                                </DropdownItem>
                                            </DropdownMenu>

                                        </UncontrolledDropdown>


                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/techher"
                                            activeClassName="active"
                                            className="nav-link">
                                            {t('TechHer')}
                                        </NavLink>
                                    </li>
                                    <li className="nav-item ab_us_xiala">
                                        {/* <NavLink to="/about-us"
                                            activeClassName="active"
                                            className="nav-link">
                                            {t('關於我們')}
                                        </NavLink> */}

                                        <UncontrolledDropdown nav inNavbar>
                                            <DropdownToggle nav caret>
                                                {t('關於我們')}
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                <DropdownItem>
                                                    <NavLink to="/about-us"
                                                        activeClassName="active"
                                                        className="nav-link">
                                                        {t('關於我們')}
                                                    </NavLink>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <NavLink to="/faq"
                                                        activeClassName="active"
                                                        className="nav-link">
                                                        {t('常見問題')}
                                                    </NavLink>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <NavLink to="/blog"
                                                        activeClassName="active"
                                                        className="nav-link">
                                                        {t('Blog')}
                                                    </NavLink>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <NavLink to="/case-studies"
                                                        activeClassName="active"
                                                        className="nav-link">
                                                        {t('個案分析')}
                                                    </NavLink>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <NavLink to="/user-experiences"
                                                        activeClassName="active"
                                                        className="nav-link">
                                                        {t('用家感受')}
                                                    </NavLink>
                                                </DropdownItem>
                                            </DropdownMenu>

                                        </UncontrolledDropdown>

                                    </li>









                                    {/* <li className="nav-item">
                                        <NavLink to="/faq"
                                            activeClassName="active"
                                            className="nav-link">
                                            {t('常見問題')}
                                        </NavLink>
                                    </li>

                                    <li className="nav-item">
                                        <NavLink to="/blog"
                                            activeClassName="active"
                                            className="nav-link">
                                            {t('Blog')}
                                        </NavLink>
                                    </li> */}

                                    <li className="language-choose">
                                        <Dropdown isOpen={dropdownCollegeMode} toggle={() => setDropdownCollegeMode(!dropdownCollegeMode)} >
                                            <DropdownToggle caret>
                                                {t('LANGUAGE_NAME_SHORT', selectLanguage)}
                                            </DropdownToggle>
                                            <DropdownMenu container="body">
                                                {
                                                    supportedLanguages.map((lang) => (
                                                        <DropdownItem key={`dropdown-${lang}`}
                                                            onClick={() => {
                                                                setSelectLanguage(lang)
                                                            }}>
                                                            <a
                                                                href={(() => {
                                                                    const urlObj = new URL(window.location.href);
                                                                    const searchObj = new URLSearchParams(urlObj.search);
                                                                    // searchObj.set('lang', lang);
                                                                    urlObj.search = searchObj.toString();

                                                                    const originUrl = urlObj.toString();
                                                                    const originHost = window.location.protocol + '//' + window.location.host;
                                                                    const str = originUrl.split(window.location.protocol + '//'); //https://进行分割，
                                                                    const index = str[1].indexOf("/") + 1; //indexOf 获取第一个斜杠的索引,
                                                                    let urlLanguage = str[1].substring(index)//substring 截取
                                                                    let firstLang = urlLanguage.split("/")[0]
                                                                    if (lang === 'zh-hant') {
                                                                        if (firstLang === 'en' || firstLang === 'cn') {
                                                                            return originUrl.replace(originHost + `/${firstLang}`, originHost)
                                                                        } else {
                                                                            return originUrl;
                                                                        }
                                                                    } else {
                                                                        if (firstLang === 'en' || firstLang === 'cn') {
                                                                            return originUrl.replace(originHost + `/${firstLang}`, originHost + `/${lang}`)
                                                                        } else {
                                                                            return originUrl.replace(originHost, originHost + `/${lang}`)
                                                                        }

                                                                    }

                                                                })()}
                                                                className="nav-link"
                                                                title="Change Language"
                                                            >
                                                                {window.t('LANGUAGE_NAME_SHORT', lang)}
                                                            </a>
                                                        </DropdownItem>
                                                    ))
                                                }
                                            </DropdownMenu>
                                        </Dropdown>
                                    </li>
                                    {/* 
                                    {supportedLanguages
                                        .filter(lang => lang !== getCurrentLang())
                                        .map(lang => {
                                            return <li key={lang} className="nav-item">
                                                <a
                                                    href={(() => {
                                                        const urlObj = new URL(window.location.href);
                                                        const searchObj = new URLSearchParams(urlObj.search);
                                                        // searchObj.set('lang', lang);
                                                        urlObj.search = searchObj.toString();

                                                        const originUrl = urlObj.toString();
                                                        const originHost = window.location.protocol + '//' + window.location.host;

                                                        const hasEn = originUrl.indexOf("/en");
                                                        if (hasEn === -1) {
                                                            return originUrl.replace(originHost, originHost + '/en')
                                                        } else {
                                                            return originUrl.replace('/en/', '/');
                                                        }
                                                        
                                                        //  return urlObj.toString();
                                                        // `/?lang=${lang}`
                                                    })()}
                                                    className="nav-link"
                                                    title="Change Language"
                                                >
                                                    {window.t('LANGUAGE_NAME_SHORT', lang)}
                                                </a>
                                            </li>;
                                        })} */}
                                    {isLoggedIn(cookies) ? (
                                        <li className="nav-item displaydesktop">
                                            <a href="#"
                                                onClick={e => e.preventDefault()}
                                                className="nav-link align-items-center p-2">
                                                {profilePic === "" ?
                                                    <i className='bx bxs-face' style={{ fontSize: "50px", width: "50px", height: "50px" }} /> :
                                                    <img src={profilePic} width={50} height={50} alt={"profile-pic"} />
                                                }
                                                <i className='bx bx-chevron-down' />
                                            </a>

                                            <ul className="dropdown-menu"
                                                style={{ left: "auto", right: 0, textAlign: "right", width: "auto" }}>
                                                <li className="nav-item">
                                                    <a href="#"
                                                        style={{ color: "grey" }}>
                                                        {getUserEmail(cookies)}
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/profile">
                                                        {t('用戶個人檔案')}
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="#"
                                                        onClick={e => {
                                                            logout(removeCookie);
                                                            let linkPath = '';
                                                            getCurrentLang() === "zh-hant" ? '/' : (getCurrentLang() === "cn" ? '/cn' : '/en')
                                                            window.location = linkPath;
                                                            e.preventDefault();
                                                        }}
                                                        className="nav-link">
                                                        {t('登出')}
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>

                                    ) : ""}
                                </ul>
                                {!isLoggedIn(cookies) ? (
                                    <div className="others-option login-option-item  d-flex align-items-center displaydesktop">
                                        <div className="option-item">
                                            <NavLink to="/login"
                                                activeClassName="active"
                                                className="default-btn">
                                                <i className="flaticon-right" />{t('登入')}
                                            </NavLink>
                                        </div>
                                    </div>
                                ) : ""}


                            </div>
                        </nav>
                    </div>
                </div>
                {<Banner />}

            </div>
        </React.Fragment>
    );
}

export default forwardRef(Navbar);


