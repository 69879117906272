
import React from 'react';
import {Col,Container, Row} from "reactstrap";

import DescriptionPhoto from '../../assets/images/ethnic-internship/description-photo.png';
import DescriptionPhotoEn from '../../assets/images/ethnic-internship/description-photo-en.png';
import Bracket from '../../assets/images/ethnic-internship/bracket.png';
import Obj1 from '../../assets/images/ethnic-internship/obj1.png';
import Obj2 from '../../assets/images/ethnic-internship/obj2.png';
import Obj3 from '../../assets/images/ethnic-internship/obj3.png';
import Vector from '../../assets/images/ethnic-internship/Vector.png';
import TasseCoffee from '../../assets/images/ethnic-internship/tasse-coffee.png';
import Openrice from '../../assets/images/ethnic-internship/openrice.png';
import HkstpLogo from '../../assets/images/ethnic-internship/HKSTP_Logo_Web_E.png';
import Dental from '../../assets/images/ethnic-internship/dental-logo-square 1.png';
import Cyberport from '../../assets/images/ethnic-internship/Cyberport_Logo_Master-01 1.png';
import Restaurant from '../../assets/images/ethnic-internship/restaurant.png';
import Businessman from '../../assets/images/ethnic-internship/hand-drawn-businessman.png';



const EthnicInternshipHome = () => {
    return (
        <>
            <div className="ethnicinternshipHome pb-70 pt-100">

                <h5 className='careerbrieftitle'>{t('少數族裔遇到的問題')}</h5>
                <p>{t("少數族裔主要來自：巴基斯坦、印尼、菲律賓、印度、尼泊爾、泰國")}</p>

                <div className='feature-item'>
                    {getCurrentLang() === 'en'?<div className='feature-item'><img src={DescriptionPhotoEn} /></div>:<div className='feature-item'><img src={DescriptionPhoto} /></div>}
                    
                </div>
                <div className='ethnicinternshipHome-emptyspeace'></div>

                <Container className='ethnicinternshipHome-container'>
                    <Row className="ethnicinternshipHome-row">
                            <Col xs lg="4">
                                <h5 className='description-title_1'>{t("人口增長")}</h5>
                            </Col>
                            <Col className="mobile-img" xs lg="1" >
                                <img className="Bracket_1" src={Bracket} />
                            </Col>
                            <Col>
                                <div>
                                    <p className='description-text_1'>{t("人口增長文案")}</p>
                                </div>
                            </Col>
                    </Row>

                    <Row className="ethnicinternshipHome-row">
                            <Col xs lg="4">
                                <h5 className='description-title_2'>{t("中文程度")}</h5>
                            </Col>
                            <Col className="mobile-img" xs lg="1">
                                <img className="Bracket_1" src={Bracket} />
                            </Col>
                            <Col>
                                <p className='description-text2'>{t("中文程度-文案")}</p>
                            </Col>
                    </Row>

                    <Row className="ethnicinternshipHome-row">
                            <Col xs lg="4">
                                <h5 className='description-title_3'>{t("工作問題")}</h5>
                            </Col>
                            <Col className="mobile-img" xs lg="1">
                                <img className="Bracket_1" src={Bracket} />
                            </Col>
                            <Col>
                                <p className='description-text3'>{t("工作問題-文案")}</p>
                            </Col>
                    </Row>
                </Container>

                <Container className='ethnicinternshipHome-container'>
                    <Row className="ethnicinternshipHome-row">
                            <Col className="mobile-img" xs lg="1">
                                <img className="Bracket_1" src={Bracket} />
                            </Col>
                            <Col >
                                <p className='description-text4'>{t("少數族裔招聘平台-橫板文案")}</p>
                            </Col>
                            <Col className="mobile-img" xs lg="1">
                                <img className="Bracket_1" src={Bracket} />
                            </Col>
                    </Row>
                </Container>
                <div className='ethnicinternshipHome-emptyspeace'></div>
                <div className='ethnicinternshipHome-emptyspeace'></div>
                <div className='ethnicinternshipHome-emptyspeace'></div>

                <h5 className='careerbrieftitle'>{t('少數族裔實習評分證書招聘平台流程')}</h5>
                <Row className="mainobject-content">
                    <Col>
                        <img src={Obj1} ></img>
                        <p className='mainobjectdec' dangerouslySetInnerHTML={{ __html: t('少數族裔參加實習計劃') }}></p>
                    </Col>
                    <Col>
                        <img src={Vector} ></img>
                    </Col>
                    <Col  xs lg="3">
                        <img src={Obj2} ></img>
                        <p className='mainobjectdec' dangerouslySetInnerHTML={{ __html: t('少數族裔實習證書') }}></p>
                    </Col>
                    <Col>
                        <img src={Vector} ></img>
                    </Col>
                    <Col xs lg="3">
                        <img src={Obj3} ></img>
                        <p className='mainobjectdec'>{t('少數族裔招聘平台')}</p>
                    </Col>
                </Row>

                <div className='ethnicinternshipHome-emptyspeace'></div>

                <h5 className='careerbrieftitle'>{t('提供實習機會的公司（提供各行各業工種、培訓）')}</h5>
                <p className='linear-line'></p>
                <div className='ethnicinternshipHome-emptyspeace'></div>

                <Container>
                    <Row className="mainobject-content">
                        <Col xs lg="3">
                            <img src={TasseCoffee} ></img>
                        </Col>
                        <Col >
                            <img src={Dental} ></img>
                        </Col>
                        <Col xs lg="6">
                            <img src={Openrice} ></img>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <img src={Restaurant} ></img>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <img src={Cyberport} ></img>
                        </Col>
                        <Col>
                            <img src={HkstpLogo} ></img>
                        </Col>
                    </Row>
                </Container>
                <div className='ethnicinternshipHome-emptyspeace'></div>
                <div className='ethnicinternshipHome-emptyspeace'></div>
                <div className='ethnicinternshipHome-emptyspeace'></div>

                <h5 className='careerbrieftitle-border current-problems-title'>{t('少數族裔參加實習計劃')}</h5>
                <div className='ethnicinternshipHome-emptyspeace'></div>
                <Row>
                    <Col>
                        <div className="ethnicinternshipHome-ui">
                            {/* <ui className="ethnicinternshipHome-ui"> */}
                                <li>{t("串連基層小店、初創提供實習機會")}</li>
                                <li>{t("各行各業")}</li>
                            {/* </ui> */}
                        </div>
                        <p className='ethnicinternshipHome-ui-text'>{t("少數族裔參加實習計劃-文案")}</p>
                    </Col>
                    <Col>
                        <img className="ethnicinternshipHome-businessman" src={Businessman} ></img>
                    </Col>
                </Row>


            </div>
            




        </>
    )
}
export default EthnicInternshipHome