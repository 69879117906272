import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import './index.css';
import {CookiesProvider} from "react-cookie";

Sentry.init({
    // dsn: "https://56bbf0e11fdc48bf81b6085348c0cc51@o783218.ingest.sentry.io/5798644",
    dsn: "https://be09848e49e2471f98fb7a677f83ffe2@o1067545.ingest.sentry.io/6061237",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // tracesSampleRate: 1.0,
     tracesSampleRate: 0,
    environment: process.env.REACT_APP_ENV,
    release: process.env.REACT_APP_GIT_COMMIT_HASH,
});

ReactDOM.render(
    <React.Fragment>
        <CookiesProvider>
            <App/>
        </CookiesProvider>
    </React.Fragment>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register( { scope: '/' });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
