import React from "react";

import {Spinner} from "reactstrap";


function SpinnerOverlay(props) {
    const {color} = props;

    return (
        <div style={{
            backgroundColor: "rgba(204, 204, 204, 0.5)",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 10000
        }}>
            <Spinner style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-16px",
                marginLeft: "-16px",
                color: color || "#ff5d22"
            }} />
        </div>
    );
}

export default SpinnerOverlay