
import React from 'react';
import {Col,Container, Row} from "reactstrap";

import certificates from '../../assets/images/ethnic-internship/certificates.png';
import ShakeHands from '../../assets/images/ethnic-internship/shake hands.png';
import HandsWithMoney from '../../assets/images/ethnic-internship/hands with money.png';
import HandWithCertificates from '../../assets/images/ethnic-internship/hand with certificates.png';
import CompanyRegister from './CompanyRegister.js'



const EthnicInternshipBottom = () => {
    return (
        <>
            <div className="ethnicinternshipHome pb-70 pt-100">

                <h5 className='careerbrieftitle-border current-problems-title'>{t('少數族裔參加實習證書')}</h5>
                <div className='ethnicinternshipHome-emptyspeace'></div>
                <Row>
                    <Col>
                        <img className="bottom_img" src={certificates} ></img>
                    </Col>
                    <Col>
                        <div className="ethnicinternshipHome-ui">
                            {/* <ui className="ethnicinternshipHome-ui"> */}
                                <li>{t("不同職業")}</li>
                                <li>{t("不同實習生")}</li>
                                <li>{t("指定實習時數")}</li>
                            {/* </ui> */}
                        </div>
                        <p className='ethnicinternshipHome-ui-text'>{t("少數族裔參加實習證書-文案")}</p>
                    </Col>
                    
                </Row>

                <div className='ethnicinternshipHome-emptyspeace'></div>
                <h5 className='careerbrieftitle-border current-problems-title'>{t('少數族裔招聘平台')}</h5>
                <div className='ethnicinternshipHome-emptyspeace'></div>
                <Row>
                    <Col>
                        <div className="ethnicinternshipHome-ui">
                            {/* <ui className="ethnicinternshipHome-ui"> */}
                                <li>{t("公司根據自己所需，選擇取得相關技能證書的求職者")}</li>
                                <li>{t("求職者技能涵蓋多個範疇，例如零售業/知識型/設計型")}</li>
                            {/* </ui> */}
                        </div>
                        <p className='ethnicinternshipHome-ui-text'>{t("少數族裔招聘平台-文案")}</p>
                    </Col>
                    <Col>
                        <img className="shakehands_img" src={ShakeHands} ></img>
                    </Col>
                </Row>

                <p className='linear-line'></p>
                {/* <div className='ethnicinternshipHome-emptyspeace'></div> */}
                <div className='ethnicinternshipHome-emptyspeace'></div>

                <CompanyRegister />
                <div className='ethnicinternshipHome-emptyspeace'></div>

{/* 
                <div>
                    <h5>公司登記表格</h5>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSclso7PZdr8FksiddL_VzKtJa_MA0BESxoiL48bn53wQxU-A/viewform">https://docs.google.com/forms/d/e/1FAIpQLSclso7PZdr8FksiddL_VzKtJa_MA0BESxoiL48bn53wQxU-A/viewform</a>
                </div> */}
                {/* <div className='ethnicinternshipHome-emptyspeace'></div> */}

                <p className='linear-line'></p>
                <div className='ethnicinternshipHome-emptyspeace'></div>


                <h5 className='careerbrieftitle'>{t('計劃優勝之處及參與者得益')}</h5>
                <div className='ethnicinternshipHome-emptyspeace'></div>

                <Row className="justify-content-md-center" >
                    <Col >
                        <h5 className='careerbrieftitle-border current-problems-title'>{t('少數族裔')}</h5>
                    </Col>
                    <Col >
                        <h5 className='careerbrieftitle-border current-problems-title'>{t('雇主')}</h5>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <img src={HandsWithMoney} ></img>
                    </Col>
                    <Col>
                        <img src={HandWithCertificates} ></img>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="ethnicinternBottom-ui">
                                <li>{t("薪資增加")}</li>
                                <li>{t("工作範籌選擇增加")}</li>
                                <li>{t("實習/ 工作經驗增加")}</li>
                        </div>
                    </Col>
                    <Col>
                        <div className="ethnicinternBottom-ui">
                                <li>{t("社會責任")}</li>
                                <li>{t("適合僱員")}</li>
                        </div>
                    </Col>
                </Row>


            </div>
            




        </>
    )
}
export default EthnicInternshipBottom