import React from 'react';
import { getCurrentLang } from '../../i18n';
import api from '../../functions/Api';

export function Banner1(props) {
  const [showBanner, setShowBanner] = React.useState(true);

  // #region fetch /api/banner/list
  const [banners, setBanners] = React.useState([]);
  const [bannerIndex, setBannerIndex] = React.useState(0);

  React.useEffect(() => {
    const bannerCur = banners[bannerIndex];
    if (bannerCur) {
      console.log(bannerCur);
      const ms = bannerCur.ms || 3000;
      const tid = setTimeout(() => {
        setBannerIndex(i => (i + 1) % banners.length);
      }, ms);

      return () => { clearTimeout(tid); };
    } else {
      // wait for banners
    }
  }, [banners, bannerIndex]);

  React.useEffect(() => {
    (async () => {
      const response = (await api.get(`/banner/list`)).data;
      setBanners(response.banner_list);
    })();
  }, []);
  // #endregion fetch /api/banner/list
  return showBanner ? <div className='banner2022' style={{ overflow: 'hidden' }}>
    <div>
      {banners
        .map(({ bannerId, contentEn, contentZh }, i) => <div id={`banner2022-${bannerId}`} key={bannerId}>
          {{ 'zh-hant': contentZh, en: contentEn }[getCurrentLang()]}
        </div>
        )}
    </div>
    <div style={{ margin: 'auto' }} />
    <svg onClick={() => setShowBanner(false)} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      width="16px" height="16px" viewBox="0 0 503.021 503.021" style={{ flexShrink: '0', cursor: 'pointer' }}>
      <path d="M491.613,75.643l-64.235-64.235c-15.202-15.202-39.854-15.202-55.056,0L251.507,132.222L130.686,11.407
c-15.202-15.202-39.853-15.202-55.055,0L11.401,75.643c-15.202,15.202-15.202,39.854,0,55.056l120.821,120.815L11.401,372.328
c-15.202,15.202-15.202,39.854,0,55.056l64.235,64.229c15.202,15.202,39.854,15.202,55.056,0l120.815-120.814l120.822,120.814
c15.202,15.202,39.854,15.202,55.056,0l64.235-64.229c15.202-15.202,15.202-39.854,0-55.056L370.793,251.514l120.82-120.815
C506.815,115.49,506.815,90.845,491.613,75.643z"/>
    </svg>
  </div> : null;
}
