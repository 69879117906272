export const institutionNameMapping = {
  CityU: t('香港城市大學'),
  HKBU: t('香港浸會大學'),
  PolyU: t('香港理工大學'),
  CUHK: t('香港中文大學'),
  HKUST: t('香港科技大學'),
  HKU: t('香港大學'),
  LU: t('嶺南大學'),
  EdUHK: t('香港教育大學'),
  OUHK: t('香港公開大學'),
  CIHE: t('明愛專上學院'),
  CHC: t('珠海學院'),
  HSUHK: t('香港恒生大學'),
  TWC: t('東華學院'),
  "VTC-THEi": t('職業訓練局 - 香港高等教育科技學院​'),
  UOWCHK: t('香港伍輪貢學院'),
  HKSYU: t('香港樹仁大學'),
};

// region items
export const currency = 'hkd'
export const items = {
  "one_test_125": {
    "amount": 12500,
    "paid_test_count": 1,
    "checkout": {
      "price": "price_1KzcN3HBmzxRitp5NZK9lEgF", //  125 HKD
      "quantity": 1
    }
  },
  "two_test_249": {
    "amount": 24900,
    "paid_test_count": 2,
    "checkout": {
      "price": "price_1JOw7GHBmzxRitp5snUFidAF", //  249 HKD
      "quantity": 1
    }
  },
  "three_test_49": {
    "amount": 4900,
    "paid_test_count": 1,
    "checkout": {
      "price": "price_1LmUwrHBmzxRitp5PTqn7gWL", //  49 HKD
      "quantity": 1
    }
  },
  // "four_test_10_coupon_discount": {
  //   "amount": 1000,
  //   "paid_test_count": 1,
  //   "checkout": {
  //     "price": "", //  10 HKD
  //     "quantity": 1
  //   }
  // }

};
export const coupon = {
  "stripe_coupon_1": {
    "id": "A8RSUzHw",
    "object": "coupon",
    "amount_off": 10,
    "created": 1682069061,
    "currency": "hkd",
    "duration": "forever",
    "duration_in_months": null,
    "livemode": true,
    "max_redemptions": null,
    "metadata": {},
    "name": null,
    "percent_off": null,
    "redeem_by": null,
    "times_redeemed": 0,
    "valid": true
  },
  "stripe_coupon_2":{
    "id": "fLeuuEWz",
    "object": "coupon",
    "amount_off": 1000, 
    "created": 1682074402,
    "currency": "hkd",
    "duration": "forever",
    "duration_in_months": null,
    "livemode": true,
    "max_redemptions": null,
    "metadata": {},
    "name": null,
    "percent_off": null,
    "redeem_by": null,
    "times_redeemed": 0,
    "valid": true
  }
}
// endregion items

// const stripe_coupon_1 = {
//   "id": "A8RSUzHw",
//   "object": "coupon",
//   "amount_off": 10,
//   "created": 1682069061,
//   "currency": "hkd",
//   "duration": "forever",
//   "duration_in_months": null,
//   "livemode": true,
//   "max_redemptions": null,
//   "metadata": {},
//   "name": null,
//   "percent_off": null,
//   "redeem_by": null,
//   "times_redeemed": 0,
//   "valid": true
// };

// #region color style
export const greenColor = 'rgb(2, 247, 118)';
export const orangeBannerColor = '#ff5d22';
// #endregion color style