import i18Ndata from "./i18n-data.json";
import i18NdataNew from "./i18n-data-new.json";
import i18NdataExtra from "./i18n-data-extra.json";
// console.log(i18Ndata);
const i18NdataMerged = {
  ...i18Ndata,
  ...i18NdataNew,
  ...i18NdataExtra,
};

const searchParams = Object.fromEntries(
  new URLSearchParams(location.search)
);

export const supportedLanguages = ['zh-hant', 'en','cn'];

// Possible urls:
// https://rightpickhk.com/?lang=zh-hant
// https://rightpickhk.com/?lang=en
// https://rightpickhk.com/?lang=XXX
// https://rightpickhk.com/
function isSupportedLang(lang) {
  return supportedLanguages.includes(lang);
}

if (searchParams.lang && isSupportedLang(searchParams.lang)) {
  // Update localStorage
  localStorage.setItem('lang', searchParams.lang);
}
const urlObj = new URL(window.location.href);
const originUrl = urlObj.toString();
if (originUrl.indexOf("/en") !== -1) {
  localStorage.setItem('lang', 'en');
} else if(originUrl.indexOf("/cn") !== -1){
  localStorage.setItem('lang', 'cn');
}else{
   localStorage.setItem('lang', 'zh-hant');

}

export function t(key, lang = getCurrentLang()) {

  if (i18NdataMerged[key]) {
    if (i18NdataMerged[key][lang]) {
      return i18NdataMerged[key][lang];
    } else {
      return key;
    }
  } else {
    return key;
  }
}

window.t = t;

export function getCurrentLang() {
  return isSupportedLang(localStorage.getItem('lang')) ?
    localStorage.getItem('lang') :
    'zh-hant';
}

window.getCurrentLang = getCurrentLang;