import React from 'react';
import { getCurrentLang } from '../../i18n';
import api from '../../functions/Api';
import { Banner1 } from './Banner1';
import {greenColor,orangeBannerColor} from '../../utils/constants'
// const greenColor = 'green';

sessionStorage.setItem("showBannerSession", "1");

function findMaxObj(arr, f) {
  let currentMax = Math.max();
  let currentMaxObj = null;

  for (const obj of arr) {
    if (f(obj) > currentMax) {
      currentMaxObj = obj;
    } else {
      // pass
    }
  }
  return currentMaxObj;
}

function Banner2(props) {

  const [showBanner, setShowBanner] = React.useState(true);
   const [bannerSession, setBannerSession] = React.useState("1");

  // #region fetch /api/banner/list
  let [banners, setBanners] = React.useState([]);
  const [bannerIndex, setBannerIndex] = React.useState(0);

  const longestBanner = findMaxObj(banners,
    b => getBannerText(b).length);

  React.useEffect(() => {

    let userJsonStr = sessionStorage.getItem('showBannerSession');
         setBannerSession(userJsonStr)
    const bannerCur = banners[bannerIndex];
    if (bannerCur && banners.length >= 2) {
      // console.log(bannerCur);
      const ms = bannerCur.ms || 2000;
      const tid = setTimeout(async () => {
        try {
          const els = [...document.querySelectorAll('.banners2022b .banner2022b')];
          await Promise.all(els.map(e => {

            // #region KeyframeEffect
            // const kf = new KeyframeEffect(
            //   e, // element to animate
            //   [
            //     { transform: 'translateY(0%)' }, // keyframe
            //     { transform: 'translateY(-100%)' } // keyframe
            //   ],
            //   {
            //     duration: 1000,
            //     // fill: 'both',
            //     fill: 'none',
            //   } // keyframe options
            // );
            // const a = new Animation(kf, document.timeline);
            // a.play();
            // #endregion KeyframeEffect

            // #region for safari
            const a2 = e.animate([
              { transform: 'translateY(0%)' }, // keyframe
              { transform: 'translateY(-100%)' } // keyframe
            ], {
              duration: 1000,
              // fill: 'both',
              fill: 'none',
            });
            // #endregion for safari

            return a2.finished;
          }));
        } catch (e) {
          console.error(e);
        }

        setBannerIndex(i => (i + 1) % banners.length);
      }, ms);

      return () => { clearTimeout(tid); };
    } else {
      // wait for banners
    }
  }, [banners, bannerIndex]);

  React.useEffect(() => {
    (async () => {
      const response = (await api.get(`/banner/list`)).data;
      setBanners(response.banner_list);
    })();
  }, []);
  // #endregion fetch /api/banner/list

  const getBannerJsx = (banner, longest) => {

    const { bannerId, contentEn, contentZh } = banner;

    const getBannerJsxInner = (text) => {
      return <div className="banner-inner2022b"
        style={{
          width: '100%'
        }}>
        <div>
          <div
            id={`banner2022b-${bannerId}`}
            key={bannerId}

          >
            {text}
          </div>
        </div>
        <div style={{ margin: 'auto' }} />
        <svg onClick={() => {
          setShowBanner(false);
          sessionStorage.removeItem("showBannerSession");
        }} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
          width="16px"  height="16px" viewBox="0 0 503.021 503.021" style={{ flexShrink: '0', cursor: 'pointer' }}>
          <path fill="currentColor"  d="M491.613,75.643l-64.235-64.235c-15.202-15.202-39.854-15.202-55.056,0L251.507,132.222L130.686,11.407 c-15.202-15.202-39.853-15.202-55.055,0L11.401,75.643c-15.202,15.202-15.202,39.854,0,55.056l120.821,120.815L11.401,372.328 c-15.202,15.202-15.202,39.854,0,55.056l64.235,64.229c15.202,15.202,39.854,15.202,55.056,0l120.815-120.814l120.822,120.814 c15.202,15.202,39.854,15.202,55.056,0l64.235-64.229c15.202-15.202,15.202-39.854,0-55.056L370.793,251.514l120.82-120.815 C506.815,115.49,506.815,90.845,491.613,75.643z" />
        </svg>
      </div >
    };

    return <div key={bannerId}
      className="banner2022b" style={{
        width: '100%',
        position: 'relative',
      }}>
      {getBannerJsxInner(longest)}
      <div style={{
        inset: 0,
        position: 'absolute',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        background: orangeBannerColor,
      }}>
        {getBannerJsxInner(getBannerText(banner))}
      </div>
    </div>
  };

  return showBanner &&bannerSession=="1" && banners.length > 0 ? <div className='banners2022b' style={{
    overflow: 'hidden',
    width: '100%',
    position: 'relative',
  }}>
    {getBannerJsx(longestBanner, getBannerText(longestBanner))}
    <div style={{
      inset: 0,
      position: 'absolute',

      // display: 'flex',
      // justifyContent: 'center',
      // alignItems: 'center',

      background: orangeBannerColor,
    }}>
      {(banners.length >= 2 ? [
        banners[bannerIndex],
        banners[(bannerIndex + 1) % banners.length],
      ] : [
        banners[bannerIndex],
      ]).map(b => getBannerJsx(b, getBannerText(longestBanner)))
      }
    </div>
  </div> : null;
}
/* 
    return <div key={bannerId}
      className="banner2022b" style={{
        width: '100%',
        position: 'relative',
      }}>
      {getBannerJsxInner(longest)}
      <div style={{
        inset: 0,
        position: 'absolute',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        background: orangeBannerColor,
      }}>
        {getBannerJsxInner({ 'zh-hant': contentZh, en: contentEn }[getCurrentLang()])}
      </div>
    </div>
  };
   */
// export const Banner = localStorage.testBanner ? Banner2 : Banner1;

export const Banner = Banner2;

function getBannerText(b) {
  return { 'zh-hant': b.contentZh, en: b.contentEn,'cn':b.contentZh }[getCurrentLang()];
}

