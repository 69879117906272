import React from 'react'
import {Button, Container, NavbarText} from "reactstrap";

import '../App.css'


function ErrorScreen() {
    return (
        <Container className="pl-3 pr-3">
            <NavbarText className="text-light">
                <i className="fas fa-warning"/>{' '}
                {t('發生錯誤')}
            </NavbarText>
            <Button color="success"
                    size="lg"
                    onClick={() => {window.location.href = '/';}}>
                {t('重新啟動')}
            </Button>
        </Container>
    )
}

export default ErrorScreen
