import React from 'react'
import { Helmet } from "react-helmet"
import "wowjs/dist/wow.min.js"

const SEO = () => {
    return (
        <div>
            <Helmet>
                <title>RightPick - { t('DSE學生大學選科大數據及人工智能系統') }</title>

                <meta property="og:image" content="https://rightpickhk.com/static/media/banner-two-main-img.4e8c56ac.png" />
                <meta name="og:title" property="og:title" content="RightPick - DSE學生大學選科大數據及人工智能系統"/>
                <meta name="twitter:card" content="RightPick - DSE學生大學選科大數據及人工智能系統"/>
                <link rel="canonical" href="https://rightpickhk.com/"/>
            </Helmet>
        </div>
    )
}

export default SEO
