import './i18n';
// console.log(t('testing'), t)

import React, { Component, Suspense, lazy } from 'react'
import * as Sentry from "@sentry/react";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AsyncComponent from './utils/AsyncComponent'
import { resizeListener } from './utils/utils'
import ErrorScreen from "./screens/ErrorScreen";
import { PrivateRoute } from './utils/PrivateRoute';
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";


import "./assets/css/animate.min.css"
import "./assets/css/bootstrap.min.css"
import "./assets/css/boxicons.min.css"
import "./assets/css/flaticon.css"
import "./assets/css/style.scss"
import "./assets/css/result_table.scss"
import "./assets/css/utils.scss"
import "./assets/css/tesprocess.scss"
import "./assets/css/personalityicon.css"
import "./assets/css/founder.scss"
import "./assets/css/newstyle.scss"
import "./assets/css/responsive.scss"

import SpinnerOverlay from "./components/SpinnerOverlay";
import EthnicInternshipScreen from './screens/EthnicInternshipScreen';

const BlogPost = lazy(() => import('./components/BigDataAnalysisStartup/BlogPost'));
const BlogDetails = lazy(() => import('./pages/blog-details'));
const Error404 = lazy(() => import('./pages/404'))
const HomeScreen = lazy(() => import('./screens/HomeScreen'))
const HomeScreenCn = lazy(() => import('./screens/HomeScreenCn'))
const SurveyScreen = lazy(() => import('./screens/SurveyScreen'))
const SurveyCnScreen = lazy(() => import('./screens/SurveyCnScreen'))
const LoginScreen = lazy(() => import('./screens/LoginScreen'))
const ProfileScreen = lazy(() => import('./screens/ProfileScreen'))
const ForgotPasswordScreen = lazy(() => import('./screens/ForgotPasswordScreen'))
const ResetPasswordScreen = lazy(() => import('./screens/ResetPasswordScreen'))
const VerifyEmailScreen = lazy(() => import('./screens/VerifyEmailScreen'))
const AboutUsScreen = lazy(() => import('./screens/AboutUsScreen'))
const AboutUsCnScreen = lazy(() => import('./screens/AboutUsCnScreen'))
const CaseStudiesScreen = lazy(() => import('./screens/CaseStudiesScreen'))
const CaseStudiesDetailScreen = lazy(() => import('./screens/CaseStudiesDetailScreen'))
const SurveyHistoryDetailScreen = lazy(() => import('./screens/SurveyHistoryDetailScreen'))
const CareerHistoryDetailScreen = lazy(() => import('./screens/CareerHistoryDetailScreen'))
const PersonalityHistoryDetailScreen = lazy(() => import('./screens/PersonalityHistoryDetailScreen'))
const FAQ = lazy(() => import('./screens/faq'))
const UserExperiences = lazy(() => import('./screens/UserExperiences'))
const TermsOfService = lazy(() => import('./screens/TermsOfService'))
const SurveyStepPTest = lazy(() => import('./screens/SurveyStepPTest'))
const SurveyHome = lazy(() => import('./screens/SurveyHome'))
const CareerScreen = lazy(() => import('./screens/CareerScreen'))
const PersonalityScreen = lazy(() => import('./screens/PersonalityScreen'))
const Founder = lazy(() => import('./screens/Founder'))
const CareerBriefScreen = lazy(() => import('./screens/CareerBriefScreen'))
const PromotionScreen = lazy(() => import('./screens/PromotionScreen'))
const SalaryCompareScreen = lazy(() => import('./screens/SalaryCompareScreen'))
const ProfileSalaryCompare = lazy(() => import('./screens/ProfileSalaryCompare'))
const SalaryHistoryDetailScreen = lazy(() => import('./screens/SalaryHistoryDetailScreen'))
const EthnicMinoritiesScreen = lazy(() => import('./screens/EthnicMinoritiesScreen'))
const TechHerScreen = lazy(() => import('./screens/TechHerScreen'))
const TechHerScreen2 = lazy(() => import('./screens/TechHerScreen2'))
const Tou = lazy(() => import('./screens/tou'))
const Dormitory = lazy(() => import('./screens/dormitory'))
const Restaurant = lazy(() => import('./screens/restaurant'))
const Discipline = lazy(() => import('./screens/discipline'))
const Activities = lazy(() => import('./screens/activities'))
const Balance = lazy(() => import('./screens/balance'))

const stripePromise = loadStripe('pk_live_51J1QmzHBmzxRitp5OJfx28zUNKbDTl4jM9883kC6zmz2eLPwUIfbXzzXL5plCvErw6AInhdSozJfRZapzHUp0xeH00UBme1w22', {
    stripeAccount: 'acct_1J1QmzHBmzxRitp5'
});

class App extends Component {
    // componentDidMount() {
    //     window.addEventListener('resize', resizeListener);
    // }
    // componentWillUnmount() {
    //     window.removeEventListener('resize', resizeListener);
    // }
    render() {
        return (
            <Sentry.ErrorBoundary fallback={ErrorScreen}>
                <Elements stripe={stripePromise}>
                    <Suspense fallback={<SpinnerOverlay />}>
                        <Router basename={getCurrentLang() === "zh-hant" ? '' : (getCurrentLang() === "cn" ? '/cn' : '/en')}>
                            <Switch>
                                <Route path={"/login"}>
                                    <LoginScreen />
                                </Route>
                                <Route path={"/profile/:category"}>
                                    <ProfileScreen />
                                </Route>
                                <Route path={"/profile"}>
                                    <Redirect to={"/profile/basic-info"} />
                                </Route>
                                <Route path={"/forgot-password"}>
                                    <ForgotPasswordScreen />
                                </Route>
                                <Route path={"/reset-password"}>
                                    <ResetPasswordScreen />
                                </Route>
                                <Route path={"/verify-email/:result"}>
                                    <VerifyEmailScreen />
                                </Route>
                                <Route path={"/about-us"}>
                                    <AboutUsScreen />
                                </Route>
                                <Route path={"/about-us-cn"}>
                                    <AboutUsCnScreen />
                                </Route>
                                <Route path={"/promotion"}>
                                    <PromotionScreen />
                                </Route>
                                <Route path={"/terms-of-service"}>
                                    <TermsOfService />
                                </Route>

                                <Route path={"/case-studies-detail/:name"}>
                                    <CaseStudiesDetailScreen />
                                </Route>
                                <Route path={"/case-studies"}>
                                    <CaseStudiesScreen />
                                </Route>
                                <Route path={"/faq"}>
                                    <FAQ />
                                </Route>
                                <Route path={"/test123"}>
                                    <SurveyStepPTest />
                                </Route>
                                <Route path={"/user-experiences"}>
                                    <UserExperiences />
                                </Route>
                                <Route path={"/survey-history/:recordId"}>
                                    <SurveyHistoryDetailScreen />
                                </Route>
                                <Route path={"/career-history/:recordId"}>
                                    <CareerHistoryDetailScreen />
                                </Route>
                                <Route path={"/personality-history/:recordId"}>
                                    <PersonalityHistoryDetailScreen />
                                </Route>
                                <Route path={"/salary-history/:recordId"}>
                                    <SalaryHistoryDetailScreen />
                                </Route>
                                <Route path={"/survey-home"}>
                                    <SurveyHome />
                                </Route>
                                <PrivateRoute path={"/survey"}>
                                    <SurveyScreen />
                                </PrivateRoute>
                                <PrivateRoute path={"/cnsurvey"}>
                                    <SurveyCnScreen />
                                </PrivateRoute>
                                <PrivateRoute path={"/career"}>
                                    <CareerScreen />
                                </PrivateRoute>
                                <PrivateRoute path={"/personality"}>
                                    <PersonalityScreen />
                                </PrivateRoute>
                                <Route path={"/career-brief"}>
                                    <CareerBriefScreen />
                                </Route>
                                <Route path={"/salary-compare"}>
                                    <SalaryCompareScreen />
                                </Route>
                                <PrivateRoute path={"/profile-salary-compare"}>
                                    < ProfileSalaryCompare />
                                </PrivateRoute>
                                <Route path={"/ethnic-minorities"}>
                                    <EthnicMinoritiesScreen />
                                </Route>
                                <Route path={"/blog"}>
                                    <BlogPost />
                                </Route>
                                <Route path={"/techher"}>
                                    <TechHerScreen />
                                </Route>
                                <Route path={"/techher2"}>
                                    <TechHerScreen2 />
                                </Route>

                                <Route path={"/blog-details/:blog_id"}>
                                    <BlogDetails />
                                </Route>
                                <Route path={"/founder"}>
                                    <Founder />
                                </Route>
                                {/* redirect to founder */}
                                <Route path={"/43LpZjP"}>
                                    <Redirect to="/founder" />
                                </Route>
                                {/* redirect to promotion page */}
                                <Route path={"/Y3jPIa3"}>
                                    <Redirect to="/promotion" />
                                </Route>
                                <Route exact path={"/"}>
                                    <HomeScreen />
                                </Route>
                                <Route exact path={"/cnHome"}>
                                    <HomeScreenCn />
                                </Route>
                                <Route exact path={"/ethnic-internship"}>
                                    <EthnicInternshipScreen />
                                </Route>
                                <Route path={"/tou"}>
                                    <Tou />
                                </Route>
                                <Route path={"/dormitory"}>
                                    <Dormitory />
                                </Route>
                                <Route path={"/restaurant"}>
                                    <Restaurant />
                                </Route>
                                <Route path={"/discipline"}>
                                    <Discipline />
                                </Route>
                                <Route path={"/activities"}>
                                    <Activities />
                                </Route>
                                <Route path={"/balance"}>
                                    <Balance />
                                </Route>
                                <Route component={Error404}></Route>
                            </Switch>
                        </Router>
                    </Suspense>
                </Elements>
                <div style={{ display: '' }} >
                    {/* <UploadHelper path="/api/survey/graduate_responses" /> */}
                </div>
            </Sentry.ErrorBoundary>
        )
    }
}

export default App
