import React from 'react'
import {Helmet} from "react-helmet";

import Footer from "../components/App/Footer";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import {Col, Container, Row } from "reactstrap";
import ethnicInternshipIndex from '../assets/images/ethnic-internship/ethnic-internship-index.png';

import EthnicInternshipHome from '../components/EthnicInternship/EthnicInternshipHome';
import EthnicInternRegister from '../components/EthnicInternship/EthnicInternRegister';
import EthnicInternshipBottom from '../components/EthnicInternship/EthnicInternshipBottom';

function EthnicInternshipScreen() {
    const [NavHeight, setNavHeight] = React.useState(150);
    const navbarref = React.createRef();
    
    return (
        <Layout>
            <Helmet>
                <title>{t('大学选科策略|少數族裔實習 - RightPick')}</title>
                {/* <meta name='description' content={t('利用大數據進行選科策略及排位，持續收集學生就業數據，進行選科評估及測驗，為你提供最佳的大學選科出路，令畢業後的發展路向更明確，立即開始測試！')} /> */}
                <link rel="alternate" hreflang="zh-Hant" href={`https://${window.location.host}/ethnic-internship`}/>
                <link rel="alternate" hreflang="en-HK"   href={`https://${window.location.host}/en/ethnic-internship`} />
                <link rel="alternate" hreflang="x-default" href={`https://${window.location.host}/ethnic-internship`} />
                <link rel="alternate" hreflang="zh-CN" href={`https://${window.location.host}/cn/ethnic-internship`} />
            </Helmet>
            <Navbar ref={navbarref} />
             <div style={{ height: NavHeight + 'px' }} />
             <Container className="careerbrief ethnic-container">
                <Row>
                    <Col xs={12} sm={12} md={6} lg={6}>
                    <div className='career-brief-title'>
                        <h1 >{t('少數族裔實習評分證書招聘平台')}</h1>
                    </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6}>
                        <div className='career-brief-col'>
                            <img src={ethnicInternshipIndex} ></img>
                        </div>
                        
                    </Col>
                </Row>

                <EthnicInternshipHome />
                <EthnicInternRegister />
                <EthnicInternshipBottom />

            </Container>

            {/* <MainBannerCnHome /> */}
            {/* <FeaturedServiceCn /> */}
            {/* <PromotionalCn/> */}
            {/* <AboutUsCn /> */}
            {/* <HomeAccomplishment /> */}
            {/* <UserTestingProcess /> */}
            {/* <PersonalityAnalysis /> */}
            {/* <影片介紹 /> */}
            {/* <用戶意見 /> */}
            {/* <HomePricingCn /> */}
            {/* <主頁分析預覽 /> */}
            {/* <HomeMediaReports /> */}
            {/* <CorporateUsers /> */}
            <Footer />
        </Layout>
    )
}

export default EthnicInternshipScreen