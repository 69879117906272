import { t } from '../i18n';

// #region const
export const pTestAnswers = {
        不同意: '不同意', // 0
        無意見: '無意見', // 1
        同意: '同意', // 2
};

export const pTestAnswerIndex = {
        不同意: 0,
        無意見: 1,
        同意: 2,
};
export const MAX_SCORE = {
        W: 7,
        S: 6,
        C: 7,
        I: 13,
        R: 7,
        E: 8,
};
/**
 * @typedef { 'W'|'S'|'C'|'I'|'R'|'E'|'' } pType
 */

/**
 * @type {{
 *   [qid: string]: {
 *      text: string,
 *      scores: [不同意: pType, 無意見: pType, 同意: pType],
 *   }
 * }}
 * 
 * @typedef {{
 *   [qid: string]: keyof typeof pTestAnswers,
 * }} pTestResult
 */
export const pTests = {
        'pTestQ01_寧願大學舒服讀完多於辛苦修讀成為專業人士學科': {
                text: t('寧願大學舒服讀完多於辛苦修讀成為專業人士學科'),
                scores: ['E', '', 'C'],
        },
        'pTestQ02_寧願學科適合自己興趣多於學科可接駁廣泛工作': {
                text: t('寧願學科適合自己興趣多於學科可接駁廣泛工作'),
                scores: ['W', '', 'I'],
        },
        'pTestQ03_寧願未來工作高人工多於大學品牌': {
                text: t('寧願未來工作高人工多於大學品牌'),
                scores: ['R', '', 'S'],
        },
        'pTestQ04_未肯定自己未來就業於某個範籌': {
                text: t('未肯定自己未來就業於某個範籌'),
                scores: ['', '', 'W'],
        },
        'pTestQ05_工作的最大目的就是薪酬，遠高於興趣或目標': {
                text: t('工作的最大目的就是薪酬，遠高於興趣或目標'),
                scores: ['I', '', 'S'],
        },
        'pTestQ06_若只有非八大的Offer，我寧願自修一年再考': {
                text: t('若只有非八大的Offer，我寧願自修一年再考'),
                scores: ['', '', 'R'],
        },
        'pTestQ07_若沒有成為專業人士的學科獲接納，我寧願自修一年再考': {
                text: t('若沒有成為專業人士的學科獲接納，我寧願自修一年再考'),
                scores: ['', '', 'E'],
        },
        'pTestQ08_我深信大學只是一張沙紙，最重要能涵蓋廣泛職業': {
                text: t('我深信大學只是一張沙紙，最重要能涵蓋廣泛職業'),
                scores: ['', '', 'W'],
        },
        'pTestQ09_我希望未來的職業可以多元化，不想只專注於一個範疇的工種。': {
                text: t('我希望未來的職業可以多元化，不想只專注於一個範疇的工種。'),
                scores: ['', '', 'W'],
        },
        'pTestQ10_我深信人需要不斷嘗試及探索新事物，對於我未來工作期望一樣。': {
                text: t('我深信人需要不斷嘗試及探索新事物，對於我未來工作期望一樣。'),
                scores: ['', '', 'W'],
        },
        'pTestQ11_對我而言，大學畢業證書是成為每個職業的入場券。': {
                text: t('對我而言，大學畢業證書是成為每個職業的入場券。'),
                scores: ['', '', 'W'],
        },
        'pTestQ12_不相信自己在大學階段已定向自己未來的職業，覺得訂立終身事業需在未來職場上經歷不同的工種。': {
                text: t('不相信自己在大學階段已定向自己未來的職業，覺得訂立終身事業需在未來職場上經歷不同的工種。'),
                scores: ['I', '', 'W'],
        },
        'pTestQ13_薪酬愈高代表人的成就愈高。': {
                text: t('薪酬愈高代表人的成就愈高。'),
                scores: ['I', '', 'S'],
        },
        'pTestQ14_我深信所有事情都可以用金錢來衡量。': {
                text: t('我深信所有事情都可以用金錢來衡量。'),
                scores: ['', '', 'S'],
        },
        'pTestQ15_我選擇工作會以薪酬作為最主要的考慮因素。': {
                text: t('我選擇工作會以薪酬作為最主要的考慮因素。'),
                scores: ['I', '', 'S'],
        },
        'pTestQ16_我覺得花四年時間讀大學得到高薪酬是合理。': {
                text: t('我覺得花四年時間讀大學得到高薪酬是合理。'),
                scores: ['', '', 'S'],
        },
        'pTestQ17_四年的大學時間應該去玩樂多於讀書，成績優秀不是我讀大學的目的。': {
                text: t('四年的大學時間應該去玩樂多於讀書，成績優秀不是我讀大學的目的。'),
                scores: ['I', '', 'C'],
        },
        'pTestQ18_我期望大學的生活多姿多彩多於吸取知識。': {
                text: t('我期望大學的生活多姿多彩多於吸取知識。'),
                scores: ['', '', 'C'],
        },
        'pTestQ19_我期望大學所選的科目可輕易取得合格成績。': {
                text: t('我期望大學所選的科目可輕易取得合格成績。'),
                scores: ['', '', 'C'],
        },
        'pTestQ20_我深信自己的性格沒有冒險精神，得過且過是我做人的宗旨。': {
                text: t('我深信自己的性格沒有冒險精神，得過且過是我做人的宗旨。'),
                scores: ['', '', 'C'],
        },
        'pTestQ21_討厭需花費額外時間做功課的學科，只想科目只有一次考試為評分的準則。': {
                text: t('討厭需花費額外時間做功課的學科，只想科目只有一次考試為評分的準則。'),
                scores: ['', '', 'C'],
        },
        'pTestQ22_我深信對學科有興趣才會用心繼續修讀。': {
                text: t('我深信對學科有興趣才會用心繼續修讀。'),
                scores: ['', '', 'I'],
        },
        'pTestQ23_由細到大，入讀大學是我的夢想，我一直以這個為目標。': {
                text: t('由細到大，入讀大學是我的夢想，我一直以這個為目標。'),
                scores: ['', '', 'I'],
        },
        'pTestQ24_我覺得人不應該隨波逐流，選擇大學學科應以自己興趣為先。': {
                text: t('我覺得人不應該隨波逐流，選擇大學學科應以自己興趣為先。'),
                scores: ['', '', 'I'],
        },
        'pTestQ25_就算香港以商為先，但不代表偏門的學科沒有社會價值。': {
                text: t('就算香港以商為先，但不代表偏門的學科沒有社會價值。'),
                scores: ['', '', 'I'],
        },
        'pTestQ26_我每年都會為自己定立目標，達成目的我會得到成就感。': {
                text: t('我每年都會為自己定立目標，達成目的我會得到成就感。'),
                scores: ['', '', 'I'],
        },
        'pTestQ27_我深信大部份公司只會取錄八大的畢業生。': {
                text: t('我深信大部份公司只會取錄八大的畢業生。'),
                scores: ['', '', 'R'],
        },
        'pTestQ28_覺得只有八大學院提供的學科才有保證，其他自資院校的學科不作考慮。': {
                text: t('覺得只有八大學院提供的學科才有保證，其他自資院校的學科不作考慮。'),
                scores: ['', '', 'R'],
        },
        'pTestQ29_若不在八大讀書，覺得自己會被人歧視。': {
                text: t('若不在八大讀書，覺得自己會被人歧視。'),
                scores: ['', '', 'R'],
        },
        'pTestQ30_就算修讀不是自己有興趣的學科，只要是八大的學科我都會接受。': {
                text: t('就算修讀不是自己有興趣的學科，只要是八大的學科我都會接受。'),
                scores: ['I', '', 'R'],
        },
        'pTestQ31_我不介意比其他人花更多的時間入讀八大，入讀八大院校的學科是我唯一的目的。': {
                text: t('我不介意比其他人花更多的時間入讀八大，入讀八大院校的學科是我唯一的目的。'),
                scores: ['', '', 'R'],
        },
        'pTestQ32_我覺得成為專業人士才有社會地位，希望大學學科可以幫助我。': {
                text: t('我覺得成為專業人士才有社會地位，希望大學學科可以幫助我。'),
                scores: ['', '', 'E'],
        },
        'pTestQ33_我覺得入讀大學是唯一幫助我考取不同牌照成為專業人士的途徑。': {
                text: t('我覺得入讀大學是唯一幫助我考取不同牌照成為專業人士的途徑。'),
                scores: ['', '', 'E'],
        },
        'pTestQ34_我覺得讀大學的目的是為自己成為專業人作準備多於玩樂而只求畢業。': {
                text: t('我覺得讀大學的目的是為自己成為專業人作準備多於玩樂而只求畢業。'),
                scores: ['C', '', 'E'],
        },
        'pTestQ35_我認為入讀大學是成為專業人士的基本條件。': {
                text: t('我認為入讀大學是成為專業人士的基本條件。'),
                scores: ['', '', 'E'],
        },
        'pTestQ36_如果不能成為專業人士我會感到自卑。': {
                text: t('如果不能成為專業人士我會感到自卑。'),
                scores: ['', '', 'E'],
        },
        'pTestQ37_寧願修讀的大學學科可令我成為專業人士為先多於以興趣。': {
                text: t('寧願修讀的大學學科可令我成為專業人士為先多於以興趣。'),
                scores: ['I', '', 'E'],
        },
};
// #endregion const

// #region helper functions

/**
 * 
 * @param {{
 *     性格測試_W: number;
 *     性格測試_S: number;
 *     性格測試_C: number;
 *     性格測試_I: number;
 *     性格測試_R: number;
 *     性格測試_E: number;
 * }} scores 
 * @param {typeof MAX_SCORE} max_score 
 * @returns 
 */
export function get_pTypes_ratio(scores, max_score) {
        if (scores && max_score) {
                return Object.fromEntries(
                        Object.entries(max_score)
                                .map(([pType, max]) => [pType, scores[`性格測試_${pType}`] / max])
                )

        } else {
                return { W: 0, S: 0, C: 0, I: 0, R: 0, E: 0 };
        }
}

/**
 * 
 * @param {{
 *     性格測試_W: number;
 *     性格測試_S: number;
 *     性格測試_C: number;
 *     性格測試_I: number;
 *     性格測試_R: number;
 *     性格測試_E: number;
 * }} scores 
 * @param {typeof MAX_SCORE} max_score 
 * @returns 
 */
export function get_pTypes_above_half(scores, max_score) {
        if (scores && max_score) {
                return Object.entries(max_score)
                        .map(([pType, max]) => [pType, scores[`性格測試_${pType}`] / max])
                        .filter(([pType, ratio]) => ratio > 0.5)
                        .map(([pType, ratio]) => pType);
        } else {
                return [];
        }
}

// #endregion helper functions
