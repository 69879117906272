// Based on this https://v5.reactrouter.com/web/example/auth-workflow

import React from "react";
import { useCookies } from "react-cookie";
import {
  Route,
  Redirect,
} from "react-router-dom";
import { isLoggedIn } from "../functions/Auth";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
/**
 * 
 * @example
 * <Switch>
 *   <Route path="/public">
 *     <PublicPage />
 *   </Route>
 *   <Route path="/login">
 *     <LoginPage />
 *   </Route>
 *   <PrivateRoute path="/protected">
 *     <ProtectedPage />
 *   </PrivateRoute>
 * </Switch>
 */
export function PrivateRoute({ children, ...rest }) {
  const [cookies, setCookie, removeCookie] = useCookies(['access_token']);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn(cookies) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}