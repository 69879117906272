import * as Sentry from "@sentry/react";
import api from './Api'
import {isDevelopment} from "./App";
export const login = async (setCookie, email, password) => {

    let response;
    
    try {
        response = await api.post('/auth/login', {email: email, password: password, lang: getCurrentLang()});
    } catch (error) {
        let errorMessage = t('登入失敗');
        if (error.response && error.response.data) {
            errorMessage = error.response.data.msg || errorMessage;
        }
        return {
            'result': 'error',
            'msg': errorMessage
        };
    }
    let token, payloadBase64, payload, options;
    token = response.data.token;
    payloadBase64 = token.split('.')[1];
    payload = JSON.parse(atob(payloadBase64));
    options = {
        path: "/",
        secure: !isDevelopment(),
        sameSite: "Lax",
        expires: new Date(payload.exp * 1000),
    };
    setCookie("access_token", response.data.token, options);
    //  Sentry set user
    Sentry.setUser({
        id: response.data.userId,
    });
    return response.data;
};

export const register = async (setCookie, email, password) => {
    let response;
    try {
        response = await api.post('/auth/register', {email: email, password: password, lang: getCurrentLang()});
    } catch (error) {
        let errorMessage = t('註冊失敗');
        if (error.response && error.response.data) {
            errorMessage = error.response.data.msg || errorMessage;
        }
        return {
            'result': 'error',
            'msg': errorMessage
        };
    }
    let token, payloadBase64, payload, options;
    token = response.data.token;
    payloadBase64 = token.split('.')[1];
    payload = JSON.parse(atob(payloadBase64));
    options = {
        path: "/",
        secure: !isDevelopment(),
        sameSite: "Lax",
        expires: new Date(payload.exp * 1000),
    };
    setCookie("access_token", token, options);
    //  Sentry set user
    Sentry.setUser({
        id: response.data.userId,
    });
    return response.data;
};

export const internshipRegister = async (name, email, countryCode, mobile, startDate, dateEnd) => {
    let response;
    try {
        response = await api.post('/auth/internship_register', {email: email, countryCode:countryCode, mobile: mobile, name: name, startDate: startDate, dateEnd:dateEnd, type: "intern", lang: getCurrentLang()});
    } catch (error) {
        let errorMessage = t('註冊失敗');
        if (error.response && error.response.data) {
            errorMessage = error.response.data.msg || errorMessage;
        }
        return {
            'result': 'error',
            'msg': errorMessage
        };
    }
    
    return {
        'result': 'success',
        'msg': '注冊成功'
        
    };
};

export const companyRegister = async (name, email, countryCode, mobile, role) => {
    let response;
    try {
        response = await api.post('/auth/internship_register', {email: email, countryCode:countryCode, mobile: mobile, name: name, role: role, type: "company", lang: getCurrentLang()});
    } catch (error) {
        let errorMessage = t('註冊失敗');
        if (error.response && error.response.data) {
            errorMessage = error.response.data.msg || errorMessage;
        }
        return {
            'result': 'error',
            'msg': errorMessage
        };
    }
    
    return {
        'result': 'success',
        'msg': '注冊成功'
        
    };
};

export const forgetPassword = async (email) => {
    let response;
    try {
        response = await api.post('/auth/forget_password', {email: email, lang: getCurrentLang()});
    } catch (error) {
        let errorMessage = t('未能發出重設密碼電郵');
        if (error.response && error.response.data) {
            errorMessage = error.response.data.msg || errorMessage;
        }
        return {
            'result': 'error',
            'msg': errorMessage
        };
    }
    return response.data;
};

export const resetPassword = async (password, token) => {
    let response;
    try {
        response = await api.post('/auth/reset_password', {password: password, token: token, lang: getCurrentLang()});
    } catch (error) {
        let errorMessage = t('未能重設密碼');
        if (error.response && error.response.data) {
            errorMessage = error.response.data.msg || errorMessage;
        }
        return {
            'result': 'error',
            'msg': errorMessage
        };
    }
    return response.data;
};

export const sendVerificationEmail = async () => {
    let response;
    try {
        response = await api.post('/auth/send_verification_email', {lang: getCurrentLang()});
    } catch (error) {
        let errorMessage = t('未能發送驗證電郵');
        if (error.response && error.response.data) {
            errorMessage = error.response.data.msg || errorMessage;
        }
        return {
            'result': 'error',
            'msg': errorMessage
        };
    }
    return response.data;
};

export const isLoggedIn = (cookies) => {
    return !!cookies.access_token; //  Check if empty or not
};

export const getUserEmail = (cookies) => {
    let payloadBase64 = cookies.access_token.split('.')[1];
    let payload = JSON.parse(atob(payloadBase64));
    return payload.email;
};

export const logout = (removeCookie,cookie) => {

    removeCookie('access_token',{path: '/'});
    removeCookie('access_token',{path: '/en'});
    removeCookie('access_token',{path: '/cn'});
    localStorage.removeItem("profilePic");
    localStorage.removeItem("responseState");
    //  Sentry set no user
    Sentry.configureScope(scope => scope.setUser(null));
};



export const getUserInfo = async () => {
    let response;
    try {
        response = await api.get('/auth/info', { lang: getCurrentLang() });
    } catch (error) {
        let errorMessage = t('未能取得用戶資料');
        if (error.response && error.response.data) {
            errorMessage = error.response.data.msg || errorMessage;
        }
        return {
            'result': 'error',
            'msg': errorMessage
        };
    }
    return response.data;
};

export const getProfilePic = async () => {
    let userInfo = await getUserInfo();
    if (userInfo.result === "error") {
        return ""
    } else {
        return userInfo.profilePic || "";
    }
};

export const getUserName = async () => {
    let userInfo = await getUserInfo();
    if (userInfo.result === "error") {
        return ""
    } else {
        return userInfo.lname+userInfo.fname || "";
    }
};

export const updateUserInfo = async (info) => {
    let response;
    try {
        response = await api.put('/auth/info', info);
    } catch (error) {
        let errorMessage = t('未能更新用戶資料');
        if (error.response && error.response.data) {
            errorMessage = error.response.data.msg || errorMessage;
        }
        return {
            'result': 'error',
            'msg': errorMessage
        };
    }
    return response.data;
};

export const getIsPaid = async () => {
    let IsPaidInfo = await getUserInfo();
    return IsPaidInfo
};



