import React from 'react'
import {useHistory} from 'react-router-dom';
import {useCookies} from "react-cookie";
import {Button, Col, Input, Row} from "reactstrap";
import emailAddresses from 'email-addresses';

import {companyRegister} from "../../functions/Auth";
import SpinnerOverlay from "../SpinnerOverlay";

const CompanyRegister = () => {
    let history = useHistory();

    const [isLoading, setIsLoading] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [mobile, setMobile] = React.useState('');
    const [countryCode, setCountryCode] = React.useState(852);
    const [name, setName] = React.useState('');
    const [role, setrole] = React.useState('');
    const [errorMessages, setErrorMessages] = React.useState([]);

    const submitForm = async () => {
        let isGoodEmail = true, checkStatus = true, messageList = [];
        let parsedEmail = emailAddresses.parseOneAddress(email);

        if (!(parsedEmail && parsedEmail.address === email)) {
            isGoodEmail = false;
            messageList.push(t('請輸入有效的電郵地址格式'));
        }
        if (!mobile){
            checkStatus = false;
            messageList.push(t('需填寫手機號碼'));
        }else{
            if (countryCode == 852){
                let regex =/^[4-9]{1}\d{7}$/;
                if (mobile.length != 8 | !regex.test(mobile)){
                    checkStatus = false;
                    messageList.push(t('請填寫正確的手機號碼'));
                }
            }
            if (countryCode == 853){
                let regex =/^[6]\d{7}$/;
                if (mobile.length != 8 | !regex.test(mobile)){
                    checkStatus = false;
                    messageList.push(t('請填寫正確的手機號碼'));
                }
            }
            if (countryCode == 86){
                let regex = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
                if (mobile.length != 11 | !regex.test(mobile)){
                    checkStatus = false;
                    messageList.push(t('請填寫正確的手機號碼'));
                }
            }
        }
        if (!role){
            checkStatus = false;
            messageList.push(t('需填寫實習角色'));
        }

        if (isGoodEmail && checkStatus) {
            setIsLoading(true);
            let response = await companyRegister(name, email, countryCode, mobile, role);
            if (response.result === 'success') {
                messageList.push("注冊成功");
                // history.push('/survey');
            } else {
                messageList.push(response.msg);
            }
            setIsLoading(false);
        }
        setErrorMessages(messageList);
    };
    return (
        <div className="login-form">
            {isLoading ? <SpinnerOverlay /> : ""}
            <h2>{t('公司註冊')}</h2>

            <form onSubmit={(e) => {
                submitForm().then();
                e.preventDefault();
                return false;
            }}>
                <div className="form-group">
                    <label>{t('聯絡人')}</label>
                    <Input type="text" className="form-control" placeholder={t('聯絡人')}
                           value={name}
                           onChange={(e) => setName(e.target.value)}
                           />
                </div>
                <div className="form-group">
                    <label>{t('電郵地址')}</label>
                    <Input type="text" className="form-control" placeholder={t('電郵地址')}
                           value={email}
                           onChange={(e) => setEmail(e.target.value)}
                           />
                </div>

                <div className="form-group">
                    <label>{t('手機')}</label>
                    <Row>
                        <Col>
                           <Input type="select" value={countryCode}
                            onChange={(e) => setCountryCode(e.target.value)}>
                                <option>
                                    852
                                </option>
                                <option>
                                    853
                                </option>
                                <option>
                                    86
                                </option>
                                
                           </Input>
                        </Col>

                        <Col>
                            <Input type="text" className="form-control" placeholder={t('手機')}
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                            />
                        </Col>
                    </Row>
                    
                </div>
                <div className="form-group">
                    <label>{t('實習角色')}</label>
                    <Input type="text" className="form-control" placeholder={t('實習角色')}
                           value={role}
                           onChange={(e) => setrole(e.target.value)}
                           onKeyPress={(e) => {
                               if (e.key === 'Enter') {
                                   submitForm().then();
                               }
                           }} />
                </div>

                <div className="row align-items-center">
                    {errorMessages.map((message, index) => (
                        <Col xs={12} key={index}>
                            <h5 className={"text-danger"}>{message}</h5>
                        </Col>
                    ))}
                </div>

                <Button onClick={(e) => {
                    submitForm().then();
                    e.preventDefault();
                    return false;
                }}>{t('註冊')}</Button>
            </form>
        </div>
    )
}

export default CompanyRegister